import { useRouter } from 'next/router';

export const useLandingPageNavigation = () => {
  const router = useRouter();

  const navigateToOnboarding = (campaignCode?: string) => {
    if (campaignCode) {
      router.push(`/onboarding?campaign=${campaignCode}`);
    } else {
      router.push('/onboarding');
    }
  };

  const navigateToLogin = () => {
    router.push('/login');
  };

  const navigateToProfile = () => {
    router.push('/profil');
  };

  const navigateToRoot = () => {
    router.replace('/');
  };

  const navigateToWowEarlybird = () => {
    router.push('/wowearlybird');
  };

  return {
    navigateToProfile,
    navigateToLogin,
    navigateToOnboarding,
    navigateToWowEarlybird,
    navigateToRoot
  };
};
