import storage from '@core/storage';
import { UserInfo } from '@core/types';
import { createStore } from '@lib/state-management';

const { saveUserStore } = storage();

const initialState: UserInfo = {
  age: 0,
  postalCode: 0,
  residents: 0,
  address: '',
  email: '',
  givenName: '',
  birthDate: '',
  lastName: '',
  hasRecentlyCancelledPolicies: false
};

const getInitialState = (): UserInfo => {
  const userJson = storage().user;

  const state = {
    ...initialState,
    ...(userJson ? JSON.parse(userJson) : {})
  };

  return state;
};

const updateFunctions = {
  updateUser: (user: Partial<UserInfo>) => (state: UserInfo) => {
    saveUserStore({ ...state, ...user });
    return user;
  },
  resetUser: () => () => {
    saveUserStore(initialState);
    return initialState;
  }
};

export const {
  useSelectFromStore: useSelectFromUserStore,
  updateStore: updateUserStore,
  resetStore: resetUserStore
} = createStore(getInitialState(), updateFunctions);
