export const DISCOUNT_CODES = [
  'EARLYBIRD',
  'WOWSTUDENT',
  'CHALMERSWOW',
  'WOWCONSOR',
  'WOWBORSGRUPPEN',
  'WOWHHGS',
  'WOWMF',
  'UEWOW',
  'USWOW',
  'SAMWOW',
  'WOWUPPSALA',
  'WOWVAST',
  'UAWOW'
] as const;

export const DISCOUNT_CODES_BY_DISCOUNT: Record<
  number,
  (typeof DISCOUNT_CODES)[number]
> = {
  0: 'EARLYBIRD'
};
