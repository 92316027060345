import { useCallback } from 'react';

import { logger } from '@lib/logger';

import { ToastMessage, updateMessageStore } from './messageStore';

const useMessaging = () => {
  const addMessage = useCallback(
    (toastMessage: ToastMessage, hideToast = false) => {
      if (toastMessage.toastType === 'error') {
        logger.error(toastMessage.message, toastMessage.payload);
      }

      if (hideToast) {
        return;
      }

      updateMessageStore('add')(toastMessage);
    },
    []
  );

  const removeMessage = useCallback((toastMessage: ToastMessage) => {
    updateMessageStore('remove')(toastMessage);
  }, []);

  return {
    addMessage,
    clearMessage: removeMessage
  };
};

export default useMessaging;
