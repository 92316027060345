import { ComponentProps } from 'react';

import { twMerge } from 'tailwind-merge';

import ActionModal from '@components/ActionModal/ActionModal';
import { Button, Divider } from '@components/index';
import { useTranslation } from '@core/translation';
import { cn, useToggle } from '@lib/utils';

import { useOnboardingTranslation } from '../translation';

export type PaymentData = {
  address: {
    street: string;
    postalCode: number;
  };
  startDate: string;
  paymentFrequency: string;
  isYearlyPayment: boolean;
  discountCode?: string;
  firstPayment: {
    amount: string;
    period: string;
  };
  price: {
    toPay: string;
    original?: string;
  };
};

type Props = {
  onClose: () => void;
  onGoToPaymentClick: (willPayLater: boolean) => void;
  onPayLaterConfirm?: () => void;
  data: PaymentData;
};

const ListItem = ({
  data
}: {
  data: {
    title: {
      className?: ComponentProps<'span'>['className'];
      value: string;
    };
    subtitle?: {
      className?: ComponentProps<'span'>['className'];
      value: string;
    };
    className?: ComponentProps<'li'>['className'];
  }[];
}) => {
  return (
    <li className="py-6">
      <ul className="py-0">
        {data.map((item, index) => (
          <li
            key={index}
            className={twMerge(
              'flex justify-between text-base font-medium leading-[22px] tracking-wide text-black',
              item.className
            )}
          >
            <span className={item.title.className}>{item.title.value}</span>
            <span className={item.subtitle?.className}>
              {item.subtitle?.value}
            </span>
          </li>
        ))}
      </ul>
    </li>
  );
};

const PaymentInfo = ({
  onPayLaterConfirm,
  onGoToPaymentClick,
  onClose,
  data
}: Pick<
  Props,
  'onPayLaterConfirm' | 'onGoToPaymentClick' | 'onClose' | 'data'
>) => {
  const ot = useOnboardingTranslation();
  const t = useTranslation('COMMON');
  return (
    <div className="flex h-full flex-col pt-8">
      <h5
        className={twMerge(
          'overflow-hidden break-normal text-[40px] font-bold text-black md:text-[48px]'
        )}
      >
        {ot('PRICE_SUGGESTION_SCREEN.HOME_INSURANCE')}
      </h5>
      <ul className="flex flex-1 flex-col">
        <ListItem
          data={[
            {
              title: {
                value: data.address.street
              },
              className: 'font-semibold'
            },
            {
              title: {
                value: data.address.postalCode.toString()
              },
              className: 'font-semibold'
            }
          ]}
        />
        <Divider />
        <ListItem
          data={[
            {
              title: {
                value: ot('PRICE_SUGGESTION_SCREEN.STARTS')
              },
              subtitle: {
                value: data.startDate
              }
            },
            {
              title: {
                value: ot('PRICE_SUGGESTION_SCREEN.PAYMENT_FREQUENCY')
              },
              subtitle: {
                value: data.paymentFrequency
              }
            }
          ]}
        />
        <Divider />
        {data.discountCode && (
          <>
            <ListItem
              data={[
                {
                  title: {
                    value: ot('PRICE_SUGGESTION_SCREEN.DISCOUNT_CODE')
                  },
                  subtitle: {
                    value: `"${data.discountCode}"`
                  }
                }
              ]}
            />
            <Divider />
          </>
        )}
        <li>
          <ul className="flex flex-row items-end justify-between">
            <ListItem
              data={[
                {
                  title: {
                    value: ot('PRICE_SUGGESTION_SCREEN.FIRST_PAYMENT')
                  },
                  className: 'font-semibold'
                },
                {
                  title: {
                    value: data.firstPayment.period
                  }
                }
              ]}
            />
            <ListItem
              data={[
                {
                  title: {
                    value: data.firstPayment.amount,
                    className: 'text-2xl font-semibold'
                  }
                }
              ]}
            />
          </ul>
        </li>
        {!data.isYearlyPayment && (
          <li className="pb-6">
            <div className="flex flex-row items-end justify-between">
              <div className="mb-2 text-base font-medium leading-[22px] tracking-wide text-black">
                {ot('PRICE_SUGGESTION_SCREEN.AFTER')}
              </div>
              <div>
                {data.price.original !== data.price.toPay && (
                  <span className={cn('mr-2 text-black line-through')}>
                    {data.price.original}
                  </span>
                )}
                <span className="text-[32px] font-bold text-black">
                  {data.price.toPay}
                </span>
              </div>
            </div>
          </li>
        )}
        <Divider />
      </ul>
      <div className="mt-8 flex flex-col-reverse items-center justify-center md:flex-row md:space-x-2">
        <Button
          variant="secondary"
          className="mt-2 border-0 md:mt-0 md:border-2"
          onClick={onPayLaterConfirm ?? onClose}
        >
          {onPayLaterConfirm
            ? ot('PRICE_SUGGESTION_SCREEN.PAY_LATER')
            : t('CANCEL')}
        </Button>
        <Button onClick={() => onGoToPaymentClick(false)}>
          {ot('TO_PAYMENT')}
        </Button>
      </div>
    </div>
  );
};

const PaymentInfoModal = ({
  onGoToPaymentClick,
  onPayLaterConfirm,
  onClose,
  data
}: Props) => {
  const t = useOnboardingTranslation();
  const { state: showPayLaterInfo, toggle: closePayLaterInfo } =
    useToggle(true);

  return (
    <ActionModal
      title={t('PRICE_SUGGESTION_SCREEN.PAY_LATER')}
      description={t('PRICE_SUGGESTION_SCREEN.PAY_LATER_DESCRIPTION')}
      onPrimaryClick={() => onGoToPaymentClick(true)}
      onSecondaryClick={onClose}
      onClose={showPayLaterInfo ? onClose : undefined}
      containerClassName={twMerge(
        'h-full md:h-auto',
        !showPayLaterInfo && 'h-auto'
      )}
    >
      {showPayLaterInfo ? (
        <PaymentInfo
          onPayLaterConfirm={onPayLaterConfirm ? closePayLaterInfo : undefined}
          onGoToPaymentClick={onGoToPaymentClick}
          onClose={onClose}
          data={data}
        />
      ) : null}
    </ActionModal>
  );
};

export default PaymentInfoModal;
