import axios from 'axios';

import { ApiResponse } from '@pages/api/onboarding/user/[personalNumber]';

import { createQuery } from '../store';

const useGetUserByPersonalNumber = (personalNumber: string) =>
  createQuery('getUserByPersonalNumber', async () => {
    const axiosResponse = await axios.get<ApiResponse>(
      `api/onboarding/user/${personalNumber}`
    );

    return axiosResponse.data;
  });

export { useGetUserByPersonalNumber };
