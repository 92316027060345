import { useEffect } from 'react';

import { useRouter } from 'next/router';

import Image_1 from '@assets/images/lp-img-1.webp';
import { ImageBlock } from '@components/index';
import { useCampaignCodeFromUrl } from '@core/discount';
import storage from '@core/storage';
import { CommonQuestionsBlock } from '@features/onboarding';
import { NextPageWithLayout } from '@pages/_app';

import {
  SeePriceSection,
  SejfaBlock,
  TrustPilotBlock,
  WowCampaignBlock
} from '../components';
import Footer from '../components/Footer';
import { useLandingPageNavigation } from '../navigation';
import { useLandingPageTranslation } from '../translation';

const LandingPage: NextPageWithLayout = () => {
  const { navigateToOnboarding } = useLandingPageNavigation();
  const { query } = useRouter();
  const { status } = query;

  const t = useLandingPageTranslation();

  const { saveCampaignCode } = storage();

  const { isCampaignCodeProvided, campaignCodeFromURL } =
    useCampaignCodeFromUrl();

  useEffect(() => {
    if (isCampaignCodeProvided && typeof campaignCodeFromURL === 'string') {
      saveCampaignCode(campaignCodeFromURL);
    }
  }, [isCampaignCodeProvided, campaignCodeFromURL, saveCampaignCode]);

  useEffect(() => {
    if (status === 'cancel') {
      navigateToOnboarding();
    }
  }, [status, navigateToOnboarding]);

  useEffect(() => {
    if (window?.sco && typeof window?.sco.initWidget === 'function') {
      window?.sco.initWidget('rechannel-id');
    }
  }, []);

  return (
    <div className="space-y-0.5 rounded-2xl md:rounded-[32px]">
      <WowCampaignBlock />
      <SejfaBlock />
      <TrustPilotBlock />
      <ImageBlock
        src={Image_1}
        rowReverse
        title={t('VALUE_PROP_1.TITLE')}
        desc={t('VALUE_PROP_1.DESC')}
        list={[
          t('VALUE_PROP_1.LIST.ITEM_1'),
          t('VALUE_PROP_1.LIST.ITEM_2'),
          t('VALUE_PROP_1.LIST.ITEM_3'),
          t('VALUE_PROP_1.LIST.ITEM_4'),
          t('VALUE_PROP_1.LIST.ITEM_5')
        ]}
      />

      <SeePriceSection onButtonClick={navigateToOnboarding} />

      <CommonQuestionsBlock
        containerClassName="bg-pressedPrimary p-4 py-16 md:p-28"
        dividerClassName="border-black"
        expandedIcon="remove"
        unexpandedIcon="add"
      />
      <div className="flex justify-center px-4 pb-12 md:pb-20">
        <div className="w-full max-w-[1216px]">
          <div
            className="py-16 md:pb-[144px] md:pt-[144px]"
            id="rechannel-id"
          />
          <div className="mb-0 border-b-2 border-white md:mb-10" />
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
