import axios from 'axios';

import { PostWowEmailRequest } from '@core/api';

import { createMutation } from '../store';

export const useSendWowEmail = createMutation({
  request: ({ email, name }: PostWowEmailRequest) =>
    axios.post('/api/email/wow', {
      email,
      name
    })
});
