import env from '@core/env-config';
import { createRequestCreatorForApi } from '@lib/api';

const offerPaths = ['cancelled', 'denied'];
const campaignPaths = ['campaigns'];
const userPaths = ['user'];

const paths = [...offerPaths, ...campaignPaths, ...userPaths] as const;

const SEJFA_API = {
  baseUrl: env.SEJFA_API_URL ?? '',
  headers: {
    Authorization: env.SEJFA_API_KEY ? `Basic ${env.SEJFA_API_KEY}` : ''
  },
  paths
} as const;

export const createSejfaRequest = createRequestCreatorForApi(SEJFA_API);
