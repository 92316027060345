import Footer from './Footer';
import Hero from './Hero';
import RichTextPage from './RichTextPage';
import SeePriceSection from './SeePriceSection';
import SejfaBlock from './SejfaBlock';
import SubpageLayout from './SubpageLayout';
import TrustPilotBlock from './TrustPilotBlock';
import { TrustPilotWidget } from './TrustPilotWidget';
import WowCampaignBlock from './WowCampaignBlock/WowCampaignBlock';

export {
  Footer,
  Hero,
  RichTextPage,
  SeePriceSection,
  SejfaBlock,
  SubpageLayout,
  TrustPilotBlock,
  TrustPilotWidget,
  WowCampaignBlock
};
