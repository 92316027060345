import { useEffect, useMemo } from 'react';

import { Button } from '@components/index';
import { queries } from '@core/api';
import { useAuthentication } from '@core/auth';
import { useCampaignCodeFromUrl } from '@core/discount';
import env from '@core/env-config';
import { useFeature } from '@core/feature-toggles';
import { useMessaging } from '@core/messaging';
import storage from '@core/storage';
import { useTranslation } from '@core/translation';
import { OnboardingData, UserInfo } from '@core/types';
import { date } from '@lib/date';
import { logger } from '@lib/logger';
import { convertNumericStringToNumber } from '@lib/utils';
import { NextPageWithLayout } from '@pages/_app';

import {
  AddressInput,
  ChatLayout,
  EstimatingModal,
  IdentifyUserButton
} from '../components';
import { ControlledChatInput } from '../components/ControlledChatInput';
import { GeneralInfoModal } from '../components/GeneralInfoModal';
import { useSoftWall } from '../hooks';
import { useAddressConfirmation } from '../hooks/useAddressConfirmationChat';
import { useLimitedOnboardingInfo } from '../hooks/useLimitedOnboardingInfo';
import { STEPS, usePriceSuggestionChat } from '../hooks/usePriceSuggestionChat';
import { useTemplateData } from '../hooks/useTemplateData';
import { useOnboardingNavigation } from '../navigation';
import {
  updateOnboardingStore,
  useSelectFromOnboardingStore
} from '../stores/onboardingStore';
import { updateUserStore, useSelectFromUserStore } from '../stores/userStore';
import { useTrackEvent } from '../tracking';
import OnboardingPayment from './OnboardingPayment';
import PriceSuggestionPageA from './PriceSuggestionPageA';
import PriceSuggestionPageB from './PriceSuggestionPageB';

const Onboarding: NextPageWithLayout = () => {
  const user = useSelectFromUserStore((state: UserInfo) => state);
  const { navigateToOnboardingFailed } = useOnboardingNavigation();
  const {
    messages,
    canWrite,
    placeholderForMessage,
    sendMessage,
    goBack,
    control,
    resetField,
    name,
    key: currentInputKey,
    keyboardType
  } = usePriceSuggestionChat();
  const { data, isLoading: isLoadingTemplateData } = useTemplateData();
  const { enabled: isSoftWallEnabled } = useSoftWall();
  const { enabled: isPriceSuggectionV2enabled } = useFeature(
    'price-suggestion-version-2'
  );

  const { useGetPoliciesList } = queries.policies;
  const {
    authenticate,
    isLoading: isAuthenticating,
    isAuthenticated
  } = useAuthentication();

  const { data: limitedOnboardingData } = useLimitedOnboardingInfo();

  const { trackEvent } = useTrackEvent();

  const { saveCampaignCode } = storage();

  const { isCampaignCodeProvided, campaignCodeFromURL } =
    useCampaignCodeFromUrl();

  useEffect(() => {
    if (isCampaignCodeProvided && typeof campaignCodeFromURL === 'string') {
      saveCampaignCode(campaignCodeFromURL);
    }
  }, [isCampaignCodeProvided, campaignCodeFromURL, saveCampaignCode]);

  const isSoftWallVisible = user?.hasRecentlyCancelledPolicies;

  useGetPoliciesList({
    options: {
      enabled: !!isAuthenticated,
      onSuccess: (data) => {
        const hasRecentlyCancelledPolicies = data?.result?.policiesList.some(
          (policy) =>
            policy.status === 'Cancelled' &&
            date.isAfter(
              new Date(policy.endDate),
              date.subMonths(new Date(), 6)
            )
        );

        if (hasRecentlyCancelledPolicies) {
          updateUser({
            hasRecentlyCancelledPolicies
          });
          trackEvent('web_soft_wall');
        }
      }
    }
  });

  const {
    canWrite: canWriteAddress,
    canChangeAddress,
    sendDefaultMessage,
    changeAddress,
    messages: addressMessages,
    sendMessage: sendAddressMessage,
    isLoading: isLoadingSimulation
  } = useAddressConfirmation(data, isLoadingTemplateData);

  const updateProgress = useMemo(
    () => updateOnboardingStore('updateProgress'),
    []
  );

  const updateUser = updateUserStore('updateUser');
  const { addMessage } = useMessaging();

  const { step, progressionStep } = useSelectFromOnboardingStore(
    (state: OnboardingData) => state
  );

  const tCommon = useTranslation('COMMON');
  const tError = useTranslation('ERROR');

  const handleIdentifyUser = async () => {
    try {
      await authenticate();
      if (env.USE_MOCK === 'true') {
        // TODO: See if we can find a cleaner solution for this.
        updateProgress({ progressionStep: 1 });
      }
    } catch (error) {
      logger.error(tError('AUTH.FAILED'), error);
      addMessage({
        message: tError('AUTH.FAILED'),
        payload: error
      });
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      updateProgress({
        step: 3,
        totalProgressionSteps: 2,
        progressionStep: 1
      });
    }
  }, [isAuthenticated, updateProgress]);

  const handleUpdateUserInformation = (value: string) => {
    if (step === STEPS.PRICE_SUGGESTION_CHAT) {
      const updateData = {
        [currentInputKey]: convertNumericStringToNumber(value)
      };

      updateUser(updateData);
    }
  };

  const handleSendMessage = (value: string) => {
    handleUpdateUserInformation(value);
    if (placeholderForMessage) {
      sendMessage(`${value} ${placeholderForMessage}`);
    } else {
      sendMessage(value);
    }
  };

  const renderPriceSuggestionChat = () => {
    return (
      <ChatLayout
        messages={messages}
        onBackClick={typeof goBack === 'function' ? goBack : undefined}
        chatClasses={'justify-end'}
        footerClasses="left-4 right-4"
      >
        <div className="w-full md:w-1/2 md:px-4">
          <ControlledChatInput
            onSend={handleSendMessage}
            valueSuffix={placeholderForMessage}
            disabled={!canWrite}
            hasFocus={canWrite}
            control={control}
            resetField={resetField}
            name={name}
            keyboardType={keyboardType}
          />
        </div>
      </ChatLayout>
    );
  };

  const renderIdentificationChat = () => (
    <ChatLayout
      messages={messages}
      onBackClick={typeof goBack === 'function' ? goBack : undefined}
      chatClasses="justify-start pt-[52px]"
      footerClasses="left-4 right-4"
    >
      <IdentifyUserButton
        onPress={handleIdentifyUser}
        isLoading={isAuthenticating}
      />
    </ChatLayout>
  );

  const renderPriceSuggestionScreen = () =>
    isPriceSuggectionV2enabled ? (
      <PriceSuggestionPageB
        onContinueClick={handleIdentifyUser}
        isLoading={isAuthenticating}
      />
    ) : (
      <PriceSuggestionPageA
        onContinueClick={handleIdentifyUser}
        isLoading={isAuthenticating}
      />
    );

  const renderAddressConfirmationChat = () => (
    <ChatLayout
      messages={addressMessages}
      chatClasses="justify-start md:pt-4"
      canGoBack={false}
    >
      <div className="flex w-full justify-center md:w-1/2 md:px-4">
        {canChangeAddress && (
          <div className="relative flex w-full justify-center gap-x-2 px-3 duration-500 ease-in-out">
            <Button
              onClick={changeAddress}
              disabled={isLoadingSimulation}
              variant="secondary"
              hugged
              className="h-[48px] w-1/2"
            >
              {tCommon('CHANGE')}
            </Button>
            <Button
              isLoading={isLoadingSimulation}
              disabled={isLoadingSimulation}
              onClick={sendDefaultMessage}
              variant="primary"
              className="w-1/2"
              hugged
            >
              {tCommon('YES')}
            </Button>
          </div>
        )}
        {!canChangeAddress && canWriteAddress && (
          <AddressInput
            className="duration-500 ease-in-out"
            onSend={sendAddressMessage}
            initialAddress={data?.result?.homeData?.address || user.address}
            initialZip={String(
              data?.result?.homeData?.postalCode || user.postalCode
            )}
            isLoading={isLoadingSimulation}
            disabled={!canWriteAddress || isLoadingSimulation}
          />
        )}
      </div>
    </ChatLayout>
  );

  const renderPaymentPage = () => {
    return <OnboardingPayment />;
  };

  const handleEstimationComplete = () => {
    updateProgress({ step, progressionStep: 1 });
  };

  const handleEstimationFailed = () => {
    navigateToOnboardingFailed('failed');
  };

  return (
    <div className="h-full">
      {step === 1 && renderPriceSuggestionChat()}
      {step === 2 && progressionStep === 0 && (
        <EstimatingModal
          onEstimationComplete={handleEstimationComplete}
          onEstimationFailed={handleEstimationFailed}
        />
      )}
      {step === 2 && progressionStep === 1 && renderPriceSuggestionScreen()}
      {step === 3 && progressionStep === 0 && renderIdentificationChat()}
      {step === 3 && progressionStep === 1 && renderAddressConfirmationChat()}
      {step === 3 && progressionStep === 2 && renderPaymentPage()}
      {isSoftWallEnabled && isSoftWallVisible && (
        <GeneralInfoModal data={limitedOnboardingData} />
      )}
    </div>
  );
};

export default Onboarding;
