import React, { PropsWithChildren } from 'react';

import { cn } from '@lib/utils';

export type BlockProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLHeadingElement>,
  HTMLDivElement
> &
  PropsWithChildren;

export const Block: React.FC<BlockProps> = ({
  children,
  className,
  ...props
}) => {
  const classes = cn(
    'h-full overflow-hidden rounded-2xl md:rounded-[32px]',
    className
  );
  return (
    <div {...props} className={classes}>
      {children}
    </div>
  );
};
