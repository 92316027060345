import axios from 'axios';

import { ApiResponse as DeniedOfferResponse } from '@pages/api/onboarding/offers/denied';

import {
  deniedOffer,
  cancelledOffer,
  earlyAdopterOffer,
  DeniedOfferRequest
} from '../requests';
import { ambassadorSignupOffer } from '../requests/offers/requests';
import { createMutation } from '../store';

const useDeniedOffer = createMutation({
  request: deniedOffer
});

const useDeniedOfferInternal = createMutation({
  request: async (body: DeniedOfferRequest) => {
    const axiosResponse = await axios.post<DeniedOfferResponse>(
      `api/onboarding/offers/denied`,
      body
    );

    return axiosResponse.data;
  }
});

const useCancelledOffer = createMutation({
  request: cancelledOffer
});

const useEarlyAdopterOffer = createMutation({
  request: earlyAdopterOffer
});

const useAmbassadorSignupOffer = createMutation({
  request: ambassadorSignupOffer
});

export {
  useDeniedOffer,
  useDeniedOfferInternal,
  useCancelledOffer,
  useEarlyAdopterOffer,
  useAmbassadorSignupOffer
};
