import axios from 'axios';

import { UserInfoResponse, UserInfoRequest } from './types';
import { createIIBRequest } from '../../api-definition';

export const getUserInfo = createIIBRequest<UserInfoResponse, void>({
  path: 'Party/UserInfo',
  method: 'GET'
});

export const updateUserInfo = (parameters: UserInfoRequest) =>
  axios.put<UserInfoResponse>('/api/profile/update', parameters);
