import { useTranslation } from '@core/translation';

export const useErrorCodeTranslation = () => {
  const t = useTranslation('ERROR');
  const getErrorTranslation = (code?: number) => {
    switch (code) {
      case 10010201: // EmailIdAlreadyExist
        return t('IIB.EMAIL_IN_USE');
      case 10030401: // ClaimPolicyMaturedDate
        return t('IIB.EXPIRED_POLICY');
      case 10030402: // InvalidDateOfDamage
        return t('IIB.INVALID_DATE');
      case 10030403: // DateOfDamageGreaterThanPolicy
        return t('IIB.DATE_BEFORE_POLICY_START');
      case 10030404: // ClaimDataIsNotExist
        return t('IIB.CLAIM_DATA');
      case 11000030: // PersonalNumberIsNotValid
        return t('IIB.INVALID_SSN');
      case 10070812: // NotAllowedCancelPolicy
        return t('IIB.CANCELLATION_INITIATED');
      case 11020303: // UserDoseNotExit
        return t('IIB.NO_USER_FOUND');
      case 10070813: // NotAllowedPaymentDetails
        return t('IIB.PAYMENTS_UNAVAILABLE');
      case 10030602: // AlreadyClaimShare
        return t('IIB.ALREADY_SHARED_CLAIM');
      case 11000029: // IsUserSanction
        return t('IIB.ABORT1');
      case 10080001: // YourTokenIsNotValid
      case 10080002: // YourTokenIsExpired
        return t('IIB.INVALID_TOKEN');
      case 10070023: // PolicyUnpaid
        return t('IIB.POLICY_UNPAID');
      case 10010299: // InvalidEmailId
        return t('IIB.EMAIL_INVALID');
      case 10070022: // PolicyAlreadyPaid
      case 11050001:
        return t('IIB.PAYMENT_ALREADY_DONE');
      case 11020996:
        return t('IIB.INVALID_DISCOUNT_CODE');
      case 10020301: // QuoteAlreadyAccpted
      case 10020302: // QuoteIsAlreadyConverted
      case 11050002: // QuoteIsAlreadyAccepted
      case 10070001: // PolicyIdMandatory
      case 10070002: // PartyIdNotExist
      case 10030301: // ClaimIdNotExist
      case 10030501: // DocumentsDataIsNotExist
      case 11000099: // RequestIsNotValid
      case 10080003: // PersonalNumberIsAlreadyExist
      case 10080101: // NotAllowedRemoveCoInsured
      case 10070012: // NotAllowedChangePolicy
      case 10030601: // NotAllowedShareClaim
      case 10070814: // NotAllowedChangeCardDetails
      case 10070815: // NotAllowedCoInsuredDetails
      case 11050911: // CoInsuredDataIsNotExist
      case 10070816: // PolicyAlreadyCancelled
      case 10070817: // CancellationRequestDateIsNotValid
      case 10070031: // PolicyNumberNotExist
      case 11050021: // NotAllowedCreatePayment
      case 11020304: // SSNDoesNotMatch
      case 11020305: // InvalidParameter
      case 11020406: // InvalidQuoteId
      case 11020407: // PaymentNotRecieved
      case 11020408: // CompanyPaymentAccountNotExist
      case 11020509: // ProductIdMandatory
      case 11020510: // SSNNumberNotExist
      case 11020511: // PaymentPeriodMandatory
      case 11020512: // PostalCodeMandatory
      case 11020513: // ResidentsNoMandatory
      case 11020614: // PaymentURLError
      case 11020615: // ErrorInSavingPaymentGateway
      case 11070001: // PolicyDocumentNotExist
      case 11070002: // InvalidObjectCatagory
      case 11070003: // ObjectDataNotExist
      case 11070004: // Somethingwentwrong
      case 11070005: // ObjectIdMandatory
      case 11070006: // NotAllowedChangeCardProcess
      case 11070007: // CardDataDoesNotExist
      case 11070008: // ChangeCardProcessOnlyForMonthly
      case 11050022: // ReturnUrlMandatory
      case 11050023: // TermUrlMandatory
      case 11050024: // ReferenceIdMandatory
      case 11050025: // InvalidServiceType
      case 11050036: // ErrorInSavingEntity
      case 11050026: // InvalidCompanyId
      case 11050027: // InvalidPaymentServiceType
      case 11050028: // InvalidQuoteStatus
      case 11050029: // AllRequiredFieldsMandatory
      case 11050030: // TransactionIdMandatory
      case 11050031: // InvalidOperationType
      case 11050032: // OperationTypeNone
      case 11050033: // MerchantIdMandatory
      case 11050034: // QuoteDataNotFound
      case 11050035: // CompanyDataNotFound
      case 10070701: // UserIdMandatory
      case 10070702: // FirstNameMandatory
      case 10070703: // LastNameMandatory
      case 10070704: // MobileNumberMandatory
      case 11090101: // ProviderMandatory
      case 11090102: // HostUrlMandatory
      case 11090103: // ProviderBankIdMandatory
      case 11090104: // AuthenticateTokenMandatory
      case 11090105: // IdentityTokenMandatory
      case 10030622: // NotAllowedRegisterClaim
      case 11040501: // ChangePostalCode
      case 11040502: // InvalidPostalCode
      case 11040504: // AddressMandatory
      case 11000020: // ValidDate
      case 11040503: // ChangePaymentMethod
      case 11000010: // InvalidPaymentMethod
      case 11000028: // PersionIdProtected
      case 11030401: // InvalidPaymentId
      case 11000401: // FutureDatePaylaterValidation
      case 11000402: // MonthlyPolicyNotSupportedPaylater
      case 11000301: // FileNameShouldBeMandatory
      case 10020601: // InvalidPackageId
      case 10020602: // PartyDataNotExist
      case 10020603: // PartyTypeNotExist
      case 10020604: // QuoteParamNotExist
      case 10070005: // PolicyDataNotExist
      case 10070006: // InvoiceDataNotFound
      case 10070007: // PartyPolicyDetailsNotFound
      case 10070008: // PolicyParamNotExist
      case 10070009: // PaymentDataNotFound
      case 10070010: // EventIdNotExist
      case 10070011: // RiskidNotExist
      case 10070024: // SettlementIdMandatory
      case 10070013: // LevelTypeNotExist
      case 10070014: // SettlementDataNotFound
      case 10070025: // CannotUpdateYearOfDamageDate
      case 10070015: // InvalidCoverName
      case 10070016: // SettlementAmountShouldBeGreatherEqualToZero
      case 10070017: // PayoutNotFound
      case 10070018: // GridDataNotFound
      case 10070019: // InvalidPageSize
      case 10070020: // InvalidTopicName
      case 10070021: // MessageDocumentDataNotExist
      default:
        return t('IIB.DEFAULT');
    }
  };
  return { getErrorTranslation };
};
