import { memo } from 'react';

import Image, { StaticImageData } from 'next/image';

import QRCodeAppStore from '@assets/images/qr-code-app-store.svg';
import QRCodePlayStore from '@assets/images/qr-code-google-play.svg';
import SejfaApp from '@assets/images/sejfa-app.webp';
import { Block, ImageBlock } from '@components/index';
import { useOnboardingTranslation } from '@features/onboarding';

import DownloadAppsSection from './DownloadAppsSection';

type Props = {
  title?: string;
  desc: string;
  imgSrc: string | StaticImageData;
};

const CampaignSuccessPage = memo(({ title, desc, imgSrc }: Props) => {
  const t = useOnboardingTranslation();

  return (
    <div className="grid flex-auto grid-cols-1 gap-0.5">
      <ImageBlock
        src={imgSrc}
        rowReverse={true}
        title={title || 'Grattis, du är sejf!'}
        titleClassName="md:text-[72px]"
        desc={desc}
      />
      <Block className="hidden items-center justify-center bg-white md:flex">
        <div className="flex md:justify-end md:pb-10 md:pr-[116px] md:pt-10">
          <div className="flex w-[500px] items-center justify-end">
            <Image src={SejfaApp} alt="Sejfa Mobile App" height={591} />
          </div>
        </div>
        <DownloadAppsSection
          title={t('SUCCESS_PAGE.TITLE')}
          description={t('SUCCESS_PAGE.DESC')}
          qrCodeAppStore={QRCodeAppStore}
          qrCodePlayStore={QRCodePlayStore}
          translations={{
            appStore: t('SUCCESS_PAGE.APP_STORE'),
            playStore: t('SUCCESS_PAGE.PLAY_STORE'),
            appStoreMobile: t('SUCCESS_PAGE.APP_STORE_MOBILE'),
            playStoreMobile: t('SUCCESS_PAGE.PLAY_STORE_MOBILE')
          }}
          className="column md:mr-32"
        />
      </Block>
    </div>
  );
});

export default CampaignSuccessPage;
