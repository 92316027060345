import { PropsWithChildren } from 'react';

import {
  documentToReactComponents,
  Options
} from '@contentful/rich-text-react-renderer';
import {
  BLOCKS,
  Document as RichTextDocument
} from '@contentful/rich-text-types';

interface RichTextProps {
  content: RichTextDocument;
}
// This component is used to render RichText from Contentful

const H3 = ({ children }: PropsWithChildren) => (
  <h3 className="mb-4 text-[40px] font-bold">{children}</h3>
);

const H4 = ({ children }: PropsWithChildren) => (
  <h4 className="mb-4 text-[32px] font-bold">{children}</h4>
);

const H5 = ({ children }: PropsWithChildren) => (
  <h5 className="mb-4 mt-10 text-[24px] font-bold">{children}</h5>
);

const RichText: React.FC<RichTextProps> = ({ content }) => {
  const renderOptions: Options = {
    renderNode: {
      [BLOCKS.UL_LIST]: (node, children) => (
        <ul className="center-marker mb-10 ml-8 mt-4 list-outside list-disc text-xl marker:text-[10px] marker:text-black">
          {children}
        </ul>
      ),
      [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
      [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <>
          <p className="text-base">{children}</p>
          <br />
        </>
      ),
      [BLOCKS.HEADING_3]: (node, children) => <H3>{children}</H3>,
      [BLOCKS.HEADING_4]: (node, children) => <H4>{children}</H4>,
      [BLOCKS.HEADING_5]: (node, children) => <H5>{children}</H5>,
      [BLOCKS.TABLE]: (node, children) => (
        <table className="my-6 w-full table-auto">{children}</table>
      ),
      [BLOCKS.TABLE_CELL]: (node, children) => (
        <td className="border-gray-200 border p-2">{children}</td>
      ),

      [BLOCKS.TABLE_HEADER_CELL]: (node, children) => (
        <th className="border-gray-200 border p-2 text-left">{children}</th>
      ),
      [BLOCKS.TABLE_ROW]: (node, children) => (
        <tr className="border-gray-200 border-b">{children}</tr>
      )
    }
  };
  return (
    <div className="break-words">
      {documentToReactComponents(content, renderOptions)}
    </div>
  );
};

export default RichText;
