import {
  CancelledOfferResponse,
  DeniedOfferRequest,
  DeniedOfferResponse,
  CancelledOfferRequest,
  EarlyAdopterOfferRequest,
  EarlyAdopterOfferResponse,
  AmbassadorSignupOfferRequest,
  AmbassadorSignupOfferResponse
} from './types';
import { createSejfaRequest } from '../../api-definition';

export const deniedOffer = createSejfaRequest<
  DeniedOfferResponse,
  DeniedOfferRequest
>({
  path: 'offers/denied',
  method: 'POST'
});

export const cancelledOffer = createSejfaRequest<
  CancelledOfferResponse,
  CancelledOfferRequest
>({
  path: 'offers/cancelled',
  method: 'POST'
});

export const earlyAdopterOffer = createSejfaRequest<
  EarlyAdopterOfferResponse,
  EarlyAdopterOfferRequest
>({
  path: 'offers/early-adopter',
  method: 'POST'
});

export const ambassadorSignupOffer = createSejfaRequest<
  AmbassadorSignupOfferResponse,
  AmbassadorSignupOfferRequest
>({
  path: 'offers/ambassador-signup',
  method: 'POST'
});
