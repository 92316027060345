/* eslint-disable @next/next/no-sync-scripts */
import { ReactElement, ReactNode, useEffect } from 'react';

import '@styles/globals.css';

import { NextPage } from 'next';
import { NextIntlProvider } from 'next-intl';
import { withPasswordProtect } from 'next-password-protect';
import type { AppProps } from 'next/app';
import { Urbanist } from 'next/font/google';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';

import { RootLayout } from '@components/index';
import env from '@core/env-config';
import Provider from '@core/provider';
import pageview from '@lib/gtm';

const urbanist = Urbanist({ subsets: ['latin'] });

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function App({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeComplete', pageview);
    return () => {
      router.events.off('routeChangeComplete', pageview);
    };
  }, [router.events]);

  function getGtmScript(): string {
    if (process.env.NODE_ENV === 'development') {
      return `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-W6BWFKV');
      `;
    } else if (env.ENV === 'STAGING') {
      return `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://gtm.sejfa.nu/gtm.js?id='+i+dl+ '&gtm_auth=T89QcD5I-Fze6Q_NrM3WcQ&gtm_preview=env-5&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-W6BWFKV')`;
    }
    return `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://gtm.sejfa.nu/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer', 'GTM-W6BWFKV');`;
  }

  const gtmScript = getGtmScript();

  const getLayout = Component.getLayout ?? ((page) => page);
  return (
    <>
      <Head>
        <title>Sejfa</title>
        <meta property="og:title" key="title" content="Sejfa" />
        <meta property="og:type" key="type" content="website" />
        <meta property="og:locale" key="locale" content="sv_SE" />
        <meta
          property="og:image"
          key="image"
          content="https://play-lh.googleusercontent.com/s0eRm6ft-DRtz64Drj9ge7ZlTRIGSxyymXMbcgOuVz1DCE56H25G_wfOOfPyU-VPJVc"
        />
        <meta
          property="og:image:secure_url"
          content="https://play-lh.googleusercontent.com/s0eRm6ft-DRtz64Drj9ge7ZlTRIGSxyymXMbcgOuVz1DCE56H25G_wfOOfPyU-VPJVc"
        />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta
          property="og:description"
          key="description"
          content="En smart digital hemförsäkring."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Script
        id="gtag-base"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: gtmScript
        }}
      />
      <Script
        type="text/javascript"
        src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        async
      ></Script>
      <NextIntlProvider
        timeZone="Europe/Stockholm"
        messages={pageProps.messages}
      >
        <Provider>
          {
            <RootLayout>
              {getLayout(
                <>
                  <style jsx global>{`
                    html {
                      font-family: ${urbanist.style.fontFamily};
                    }
                  `}</style>
                  <Component {...pageProps} />
                </>
              )}
            </RootLayout>
          }
        </Provider>
      </NextIntlProvider>
    </>
  );
}
export default env.ENV === 'STAGING'
  ? withPasswordProtect(App, {
      loginApiUrl: '/api/login',
      loginComponentProps: {
        logo: 'https://s3-kna1.citycloud.com:8080/158eebccf8734db1b94d15cbff2fa809:read-bucket/Logo_Large.png',
        buttonBackgroundColor: '#AA77E5'
      }
    })
  : App;
