import { useCallback } from 'react';

import { useRouter } from 'next/router';

import storage from '@core/storage';
import { DISCOUNT_CODES as SafetyByCiliaDiscountCodes } from '@features/safety-by-cilia';
import { DISCOUNT_CODES as WowEarlybirdPageDiscountCodes } from '@features/wowearlybird';

type UseSignupSuccessResult = {
  handleSignupSuccess: () => void;
};

export const useSignupSuccess = (): UseSignupSuccessResult => {
  const router = useRouter();

  const handleSignupSuccess = useCallback(() => {
    const { campaignCode } = storage();

    if (
      SafetyByCiliaDiscountCodes.includes(
        campaignCode as (typeof SafetyByCiliaDiscountCodes)[number]
      )
    ) {
      /* Default case -> navigate to onboarding success */
      router.push('/safety-by-cilia/success');
      return;
    }

    if (
      WowEarlybirdPageDiscountCodes.includes(
        campaignCode as (typeof WowEarlybirdPageDiscountCodes)[number]
      )
    ) {
      /* Default case -> navigate to onboarding success */
      router.push('/wowearlybird/success');
      return;
    }

    /* Default case -> navigate to onboarding success */
    router.push('/onboarding/success');
  }, [router]);

  return {
    handleSignupSuccess
  };
};
