import React from 'react';

import { twMerge } from 'tailwind-merge';

import { ActivityIndicator, ButtonProps, Icon, IconType } from '..';

type IconButtonProps = {
  name?: IconType;
  activityIndicatorContainer?: string;
  description?: string;
} & ButtonProps;

export const IconButton: React.FC<IconButtonProps> = ({
  name,
  className,
  disabled,
  isLoading,
  iconClassName,
  activityIndicatorContainer,
  description = 'Icon description',
  ...props
}) => {
  const baseClasses =
    'bg-white mr-0 inline-flex h-12 w-12 items-center justify-center rounded-full text-center text-base font-medium text-black active:opacity-60';

  const classes = twMerge(baseClasses, className, disabled && 'bg-disabled');

  return (
    <button disabled={disabled} type="button" className={classes} {...props}>
      {isLoading ? (
        <span className={twMerge('ml-2.5', activityIndicatorContainer)}>
          <ActivityIndicator />
        </span>
      ) : (
        <>
          <Icon name={name} className={iconClassName} />
          <span className="sr-only">{description}</span>
        </>
      )}
    </button>
  );
};
