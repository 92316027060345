import Image, { StaticImageData } from 'next/image';

import { cn } from '@lib/utils';

type ImageSectionProps = {
  src: string | StaticImageData;
  alt: string;
  rowReverse?: boolean;
  imgContainerClassName?: string;
};

const ImageSection = ({
  src,
  alt,
  rowReverse,
  imgContainerClassName
}: ImageSectionProps) => (
  <div
    className={cn(
      'flex justify-center text-black md:justify-end md:pr-[136px]',
      rowReverse
        ? 'md:justify-en order-first mt-0 md:order-last md:pr-0'
        : 'order-last md:order-first',
      imgContainerClassName
    )}
  >
    <Image
      src={src}
      alt={alt}
      priority
      width={488}
      height={488}
      className="md:min-h-[488px] md:min-w-[488px]"
    />
  </div>
);

export default ImageSection;
