import React, { HTMLAttributes } from 'react';

import { cn } from '@lib/utils';

type Props = HTMLAttributes<HTMLDivElement>;

const CampaignPageContainer = ({ className, children, ...props }: Props) => {
  return (
    <div
      className={cn(
        'h-full space-y-0.5 rounded-2xl md:rounded-[32px]',
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export default CampaignPageContainer;
