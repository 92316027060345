import Background from '@assets/images/wow-hero-bg.webp';
import Logo from '@assets/images/wow-logo.webp';
import CampaignPageContainer from '@components/CampaignPageContainer/CampaignPageContainer';
import CampaignPageFooter from '@components/CampaignPageFooter/CampaignPageFooter';
import { CampaignPageHero } from '@components/index';
import { useLandingPageNavigation } from '@features/landing-page';
import { NextPageWithLayout } from '@pages/_app';

import CampaignPageSections from '../components/CampaignPageSections';
import { DISCOUNT_CODES_BY_DISCOUNT } from '../constants';

export const WowEarlybirdPage: NextPageWithLayout = () => {
  const { navigateToOnboarding } = useLandingPageNavigation();
  return (
    <CampaignPageContainer>
      <CampaignPageHero
        cta={() => navigateToOnboarding(DISCOUNT_CODES_BY_DISCOUNT[0])}
        title={
          'Just nu - Signa Sejfa och få en tredagarsbiljett till Way Out West!\nGäller fram till 31 December 2024'
        }
        titleClassName="text-2xl md:text-[2rem] font-semibold md:font-bold"
        logoClassName="h-[137px] md:h-[210px] md:mb-8"
        bgImg={{ src: Background, alt: 'Wow hero background' }}
        campaignImg={{ src: Logo, alt: 'Wow campaign logo' }}
        ctaContainerClassName="mb-20 md:mb-0"
        middleImgClassName="my-6"
      />
      <CampaignPageSections />
      <CampaignPageFooter />
    </CampaignPageContainer>
  );
};
