type FormatterProps = {
  text?: string; // Make text optional
};

const TextFormatter = ({ text = '' }: FormatterProps) => {
  // Provide default empty string
  const processText = (input: string) => {
    // First split by newlines
    return input.split('\n').map((line, lineIndex) => {
      // Then process each line for both bold text and links
      const parts = line.split(/(\*[^*]+\*|\[[^\]]+\]\([^)]+\))/g);

      return (
        <span key={lineIndex}>
          {parts.map((part, partIndex) => {
            // Handle bold text (wrapped in asterisks)
            if (part.startsWith('*') && part.endsWith('*')) {
              return <strong key={partIndex}>{part.slice(1, -1)}</strong>;
            }

            // Handle links [text](url)
            if (part.match(/^\[[^\]]+\]\([^)]+\)$/)) {
              const linkText = part.match(/\[(.*?)\]/)?.[1] || '';
              const url = part.match(/\((.*?)\)/)?.[1] || '';
              return (
                <a
                  key={partIndex}
                  href={url}
                  className="text-blue-600 hover:text-blue-800 cursor-pointer underline"
                >
                  {linkText}
                </a>
              );
            }

            // Return regular text
            return part;
          })}
          <br />
        </span>
      );
    });
  };

  return <span>{processText(text)}</span>;
};

export default TextFormatter;
