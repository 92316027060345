import { StaticImageData } from 'next/image';

import { Block } from '@components/index';
import { cn } from '@lib/utils';

import ContentSection, { ContentSectionProps } from './ContentSection';
import ImageSection from './ImageSection';

export type CampaignPageSectionProps = ContentSectionProps & {
  rowReverse?: boolean;
  src?: string | StaticImageData;
  srcAlt?: string;
  imgContainerClassName?: string;
  blockClassName?: string;
};

const CampaignPageSection = ({
  rowReverse,
  src,
  srcAlt,
  imgContainerClassName,
  blockClassName,
  ...rest
}: CampaignPageSectionProps) => (
  <section>
    <Block
      className={cn('flex w-full justify-center bg-white', blockClassName)}
    >
      <div
        className={cn(
          'max-w-[1216px] items-center px-4 py-10 md:space-y-0 md:py-28',
          {
            'flex justify-center': !src,
            'grid md:grid-cols-2': !!src
          }
        )}
      >
        {!!src && (
          <ImageSection
            src={src}
            alt={srcAlt ?? 'An image'}
            rowReverse={rowReverse}
            imgContainerClassName={imgContainerClassName}
          />
        )}
        <div className="w-full">
          <ContentSection
            rowReverse={rowReverse}
            contentClassName="md:max-w-full"
            {...rest}
          />
        </div>
      </div>
    </Block>
  </section>
);

export default CampaignPageSection;
