import axios from 'axios';

import { createQuery } from '../store';

const useGetUserInfo = createQuery('getUserInfo', () =>
  axios.get('/api/profile/user-info')
);

// const useGetUserInfo = ({ options: { onSuccess } }: any) => {
//   return useQuery({
//     queryKey: ['getUserInfo'],
//     queryFn: () => axios.get('/api/profile/user-info'),
//     onSuccess: (data) => onSuccess(data.data)
//   });
// };

const api = {
  useGetUserInfo
};

export default api;
