import { useCallback } from 'react';

type Options = {
  newWindow?: boolean;
  onError?: (error: string) => void;
};

export const useUrlHandler = (options?: Options) => {
  const { newWindow = true, onError } = options ?? {};

  const openUrl = useCallback(
    async (url: string) => {
      try {
        const urlToOpen = url.startsWith('http') ? url : `https://${url}`;

        if (newWindow) {
          window.open(urlToOpen, '_blank', 'noopener,noreferrer');
        } else {
          window.location.href = urlToOpen;
        }

        return true;
      } catch (error) {
        const errorMessage = 'Failed to open URL';
        onError?.(errorMessage);
        return false;
      }
    },
    [newWindow, onError]
  );

  return {
    openUrl
  };
};
