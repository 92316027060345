import { useCallback, useEffect, useMemo, useRef } from 'react';

import WowSuccessImage from '@assets/images/wow-success.webp';
import CampaignPageFooter from '@components/CampaignPageFooter/CampaignPageFooter';
import CampaignSuccessPage from '@components/CampaignSuccessPage/CampaignSuccessPage';
import { useSendWowEmail } from '@core/api';
import { resetStores, useSelectFromUserStore } from '@features/onboarding';
import { NextPageWithLayout } from '@pages/_app';

export const WowEarlybirdSuccessPage: NextPageWithLayout = () => {
  const hasSentEmail = useRef(false);
  const { email, givenName } = useSelectFromUserStore((state) => state);

  const onError = useCallback(() => {
    resetStores();
  }, []);

  const onSuccess = useCallback(() => {
    resetStores();
  }, []);

  const options = useMemo(
    () => ({ onError, onSuccess, retry: 3 }),
    [onError, onSuccess]
  );

  const { mutate: send } = useSendWowEmail(options);

  useEffect(() => {
    if (hasSentEmail.current || !email || !givenName) return;

    send({ email, name: givenName });
    hasSentEmail.current = true;
  }, [send, email, givenName]);

  return (
    <>
      <CampaignSuccessPage
        imgSrc={WowSuccessImage}
        desc={`Och snart är det du som står på Way Out West och njuter! Du får inom kort ett bekräftelsemail, både med information om din nya hemförsäkring samt om dina Way Out West biljetter.\n\nHar du några frågor är det bara att höra av dig i chatten ❤️`}
      />
      <CampaignPageFooter />
    </>
  );
};
