import { ProductDataResponse } from './types';
import { createIIBRequest } from '../../api-definition';

export const getProductList = createIIBRequest<ProductDataResponse, void>({
  path: 'Products/GetProductList',
  method: 'GET'
});

export const getProducts = createIIBRequest<ProductDataResponse, void>({
  path: 'Products',
  method: 'GET'
});
