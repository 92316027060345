import { useCallback, useRef, useState } from 'react';

import { mutations, queries } from '@core/api';
import { useMessaging } from '@core/messaging';
import storage from '@core/storage';
import { useTranslation } from '@core/translation';
import { logger } from '@lib/logger';
import { useLocation, useToggle } from '@lib/utils';

import useBankId from './useBankId';
import { AuthResponse } from '../../lib/auth/types';

const sessionStorage = storage();

const useAuthentication = () => {
  const { addMessage } = useMessaging();
  const isAuthInitiated = useRef(false);

  const tError = useTranslation('ERROR');
  const { location } = useLocation();
  const { state: isLoading, open: showLoader, close: hideLoader } = useToggle();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const { useCreateToken } = queries.onboard;
  const { useValidateToken } = mutations.onboard;
  const updateToken = (accessToken?: string) => {
    if (accessToken) {
      sessionStorage.saveAccessToken(accessToken);
      setIsAuthenticated(true);
      hideLoader();
    }
  };

  const { mutate: validateToken, isLoading: isValidatingToken } =
    useValidateToken({
      onSuccess: (data) => {
        updateToken(data?.result?.accessToken);
      },
      onError: (error) => {
        logger.error('Validate token error', error);
        addMessage({
          message: tError('AUTH.VALIDATE_TOKEN_FAILED'),
          payload: error
        });
        isAuthInitiated.current = false;
      },
      retry: 3
    });

  const onError = useCallback(
    (response: AuthResponse) => {
      const { type } = response;

      let errorMessage = '';

      if (type === 'cancelled') {
        errorMessage = tError('AUTH.USER_CANCELLED');
        hideLoader();
      }

      if (type === 'error') {
        errorMessage = tError('UNEXPECTED_ERROR');
        hideLoader();
      }

      if (type === 'failed') {
        errorMessage = tError('AUTH.FAILED');
        hideLoader();
      }

      logger.error(
        `useAuthentication error with type ${type}. Response: `,
        response
      );

      if (errorMessage) {
        addMessage({
          message: errorMessage
        });
      }
    },
    [hideLoader, addMessage, tError]
  );

  const authCallback = useCallback(
    async (response: AuthResponse) => {
      if (isAuthInitiated.current) return;
      isAuthInitiated.current = true;
      if (response.type === 'authenticated' && response.success) {
        const { success, type, token, provider, transactionId } = response;
        validateToken({
          success,
          type,
          identityToken: token,
          provider,
          transactionId
        });
        return;
      }

      setIsAuthenticated(false);
    },
    [validateToken]
  );

  const { authenticateWithBankId } = useBankId(authCallback, onError);

  const { refetch } = useCreateToken({
    parameters: {
      hostUrl: location?.origin || 'http://localhost:3000',
      provider: 'se-bankid'
    },
    options: {
      enabled: false,
      onError: (error) => {
        logger.error('Create token error', error);
        addMessage({
          message: tError('AUTH.CREATE_TOKEN_FAILED'),
          payload: error
        });
      }
    }
  });

  const authenticate = useCallback(async () => {
    showLoader();
    try {
      const { data } = await refetch();

      if (data?.result) {
        sessionStorage.saveAuthKey(data.result.authorizationKey);
        authenticateWithBankId(data.result.authorizationKey);
      }
    } catch (error) {
      addMessage({
        message: tError('UNEXPECTED_ERROR'),
        payload: error
      });
      logger.error('Authenticate error: ', error);
      hideLoader();
    }
  }, []);

  return {
    authenticate,
    isAuthenticated,
    isLoading: isLoading || isValidatingToken
  };
};

export default useAuthentication;
