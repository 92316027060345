'use client';
import { useCallback, useEffect } from 'react';

import { useRouter } from 'next/router';

import { ActivityIndicator, Block } from '@components/index';
import { useMessaging } from '@core/messaging';
import { useSignupSuccess } from '@core/signup-success';
import { useTranslation } from '@core/translation';
import { delay } from '@lib/utils';
import { NextPageWithLayout } from '@pages/_app';

type Props = {
  onSuccess?: () => void;
  onError?: (err: string) => void;
};

const LoadingPayment: NextPageWithLayout<Props> = () => {
  const router = useRouter();
  const tError = useTranslation('ERROR');
  const { addMessage } = useMessaging();

  const { responseCode, paymentid } = router.query;

  const { handleSignupSuccess } = useSignupSuccess();

  const navigateToPaymentSuccess = useCallback(async () => {
    await delay(2000); // workaround for discount initial undefined value
    handleSignupSuccess();
  }, [handleSignupSuccess]);

  useEffect(() => {
    if (paymentid) {
      navigateToPaymentSuccess();
    } else {
      addMessage(
        {
          message:
            responseCode === 'Cancel'
              ? tError('PAYMENT.CANCELLED')
              : tError('PAYMENT.FAILED')
        },
        true
      );
    }
  }, [paymentid, addMessage, navigateToPaymentSuccess, responseCode, tError]);

  return (
    <Block className="flex items-center justify-center bg-white">
      <div className="column p-24">
        <div>
          <ActivityIndicator />
        </div>
      </div>
    </Block>
  );
};

export default LoadingPayment;
