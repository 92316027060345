import Image, { ImageProps } from 'next/image';

import Heart from '@assets/images/safety-by-cilia-heart.svg';
import SejfaLogo from '@assets/sejfa-logo.svg';
import { cn } from '@lib/utils';

type LogoSectionProps = {
  campaignImg: Pick<ImageProps, 'src' | 'alt'>;
  logoClassName?: string;
  middleImgClassName?: string;
};

const LogoSection = ({
  campaignImg,
  logoClassName,
  middleImgClassName
}: LogoSectionProps) => (
  <div className="z-2 flex flex-1 flex-col items-center justify-center md:flex-row md:py-0">
    <Image
      src={SejfaLogo}
      alt="Sejfa logo"
      priority
      className="h-auto w-[180px] md:mb-0 md:ml-0 md:h-[116px] md:w-auto"
    />
    <Image
      src={Heart}
      alt="Heart"
      priority
      className={cn(
        'my-8 h-[60px] w-[65px] md:mx-[50px] md:my-0 md:h-[82px] md:w-[88px]',
        middleImgClassName
      )}
    />
    <Image
      src={campaignImg.src}
      alt={campaignImg.alt}
      priority
      className={cn('h-[77px] w-auto md:mr-0 md:h-24 md:w-auto', logoClassName)}
    />
  </div>
);

export default LogoSection;
