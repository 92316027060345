import {
  ValidateTokenRequest,
  AuthResponse,
  CreateTokenResponse,
  CreateTokenParams
} from './types';
import { createIIBRequest } from '../../api-definition';

export const createToken = createIIBRequest<
  CreateTokenResponse,
  CreateTokenParams
>({
  path: 'Users/CreateToken',
  method: 'GET',
  headers: {
    Authorization: undefined // Request from developers of IIB API to not include Authorization header for this endpoint in order to avoid misleading error messages
  }
});

export const validateToken = createIIBRequest<
  AuthResponse,
  ValidateTokenRequest
>({
  path: 'Users/ValidateToken',
  method: 'POST'
});

export {
  type CreateTokenParams,
  type CreateTokenResponse,
  type AuthResponse,
  type ValidateTokenRequest
};
