import { CreatePaymentRequest, CreatePaymentResponse } from './types';
import { createIIBRequest } from '../../api-definition';

export const createPayment = createIIBRequest<
  CreatePaymentResponse,
  CreatePaymentRequest
>({
  path: 'Payment/Create',
  method: 'POST'
});
