// DownloadAppsSection.tsx
import React from 'react';

import Image, { StaticImageData } from 'next/image';

import { Button, Block, Icon } from '@components/index';

type Props = {
  title: string;
  description: string;
  qrCodeAppStore: StaticImageData;
  qrCodePlayStore: StaticImageData;
  translations: {
    appStore: string;
    playStore: string;
    appStoreMobile: string;
    playStoreMobile: string;
  };
  className?: string;
};

const DownloadAppsSection = ({
  title,
  description,
  qrCodeAppStore,
  qrCodePlayStore,
  translations,
  className
}: Props) => {
  const openUrl = (url: string) => window.open(url, '_blank');

  const goToPlayStore = () =>
    openUrl('https://play.google.com/store/apps/details?id=nu.sejfa.android');

  const goToAppStore = () =>
    openUrl('https://apps.apple.com/us/app/sejfa/id6449179160');

  const renderAppQR = (
    src: string | StaticImageData,
    title: string,
    onClick: () => void
  ) => {
    return (
      <div className="max-h-[140px] max-w-[140px] rounded-2xl border border-greySemiLight p-2">
        <Image src={src} alt="app-store-qr" />
        <Button
          icon="arrow-angle-up"
          className="mt-4 h-[40px] w-full justify-start self-end bg-transparent pl-2"
          onClick={onClick}
          hugged
        >
          {title}
        </Button>
      </div>
    );
  };

  const renderAppButton = (title: string, onClick: () => void) => {
    return (
      <Block
        className="flex h-[162px] w-[167px] cursor-pointer flex-col justify-between rounded-2xl bg-success p-4 active:bg-success/40 active:text-black/40"
        onClick={onClick}
      >
        <Icon name="arrow-angle-up" className="self-end" />
        <div className="text-2xl font-semibold">{title}</div>
      </Block>
    );
  };

  return (
    <div className={className}>
      <p className="text-[40px] font-semibold">{title}</p>
      <p className="font-semi-bold my-4 text-xl md:my-6">{description}</p>

      <div className="hidden space-x-9 md:flex">
        {renderAppQR(qrCodeAppStore, translations.appStore, goToAppStore)}
        {renderAppQR(qrCodePlayStore, translations.playStore, goToPlayStore)}
      </div>

      <div className="flex space-x-9 md:hidden">
        {renderAppButton(translations.appStoreMobile, goToAppStore)}
        {renderAppButton(translations.playStoreMobile, goToPlayStore)}
      </div>
    </div>
  );
};

export default DownloadAppsSection;
