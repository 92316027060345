import {
  CalculateQuoteRequest,
  CalculateQuoteResponse,
  CheckSimulationStatusRequest,
  CheckSimulationStatusResponse,
  ConvertQuoteToPolicyRequest,
  ConvertQuoteToPolicyResponse,
  CreateQuoteRequest,
  CreateQuoteResponse,
  GetTemplateResponse,
  QuoteEditDetails,
  QuoteInitiateDetails,
  QuoteListResponse,
  QuoteViewDetailsResonse,
  SendEmailVerificationRequest,
  SendVerificationResponse
} from './types';
import { createIIBRequest } from '../../api-definition';

export const getInitiate = createIIBRequest<QuoteInitiateDetails, string>({
  path: 'Quotes/GetInitiate',
  method: 'GET'
});

export const getQuoteDetailsById = createIIBRequest<QuoteEditDetails, number>({
  path: 'Quotes/GetQuoteDetailsById',
  method: 'GET'
});

export const getAllList = createIIBRequest<QuoteListResponse, void>({
  path: 'Quotes/GetAllList',
  method: 'GET'
});

export const getViewById = createIIBRequest<QuoteViewDetailsResonse, number>({
  path: 'Quotes/GetViewById',
  method: 'GET'
});

export const sendEmailVerificationLink = createIIBRequest<
  SendVerificationResponse,
  SendEmailVerificationRequest
>({
  path: 'Quotes/SendEmailVerificationLink',
  method: 'POST'
});

export const calculateQuote = createIIBRequest<
  CalculateQuoteResponse,
  CalculateQuoteRequest
>({
  path: 'Quotes/Calculate',
  method: 'POST'
});
export const createQuote = createIIBRequest<
  CreateQuoteResponse,
  CreateQuoteRequest
>({
  path: 'Quotes/Create',
  method: 'POST'
});

export const convertQuoteToPolicy = createIIBRequest<
  ConvertQuoteToPolicyResponse,
  ConvertQuoteToPolicyRequest
>({
  path: 'Quotes/QuoteToPolicy',
  method: 'POST'
});

export const simulateQuote = createIIBRequest<
  CheckSimulationStatusResponse,
  CheckSimulationStatusRequest
>({
  path: 'Quotes/TryAndPlay',
  method: 'POST'
});

export const getTemplate = createIIBRequest<
  GetTemplateResponse,
  { productId: string }
>({
  path: 'Quotes/Template',
  method: 'GET'
});
