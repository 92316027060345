import { createSejfaRequest } from '../../api-definition';
import { PostWowEmailRequest, PostWowEmailResponse } from './types';

export const sendWowEmail = createSejfaRequest<
  PostWowEmailResponse,
  PostWowEmailRequest
>({
  path: 'email/wow',
  method: 'POST'
});
