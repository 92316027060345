import Image from 'next/image';

import ChatbotSV from '@assets/images/chatbot-sv.svg';
import { Button } from '@components/index';

import { useLandingPageTranslation } from '../translation';

type Props = {
  onButtonClick: () => void;
};

const SeePriceSection = ({ onButtonClick }: Props) => {
  const t = useLandingPageTranslation();
  return (
    <div className=" flex w-full justify-center">
      <div className="flex w-full max-w-[1216px] flex-col-reverse md:flex-row">
        <div className="-mb-[14px] flex flex-1 items-end justify-center md:justify-end">
          <Image src={ChatbotSV} alt="chatbot-image" />
        </div>
        <div className="items-end justify-end md:pb-[240px] md:pt-28">
          <div className="align-center flex flex-col items-center justify-center space-y-[72px] py-16 md:py-0">
            <h3 className="px-4 text-[40px] text-white">
              {t('SEJFA_YOUR_HOME_ALREADY_NOW')}
            </h3>
            <Button
              variant="secondary"
              className="h-[72px] md:max-w-[280px]"
              onClick={() => onButtonClick()}
            >
              {t('CALL_TO_ACTION')}
            </Button>
          </div>
        </div>
        <div className="flex-1" />
      </div>
    </div>
  );
};

export default SeePriceSection;
