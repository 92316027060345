import React from 'react';

import { Button } from '@components/index';
import { cn } from '@lib/utils';

import ListItem from './ListItem';
import TextFormatter from './TextFormatter';

type Pill = {
  className?: string;
  textClassName?: string;
  text: string;
};

export type ContentSectionProps = {
  title: string;
  desc?: string;
  list?: string[];
  cta?: () => void;
  ctaTitle?: string;
  ctaDesc?: string;
  ctaClassName?: string;
  ctaDescClassName?: string;
  titleClassName?: string;
  listClassName?: string;
  descClassName?: string;
  contentClassName?: string;
  rowReverse?: boolean;
  note?: React.ReactNode;
  pill?: Pill;
};

const ContentSection = ({
  title,
  desc,
  list,
  cta,
  ctaTitle,
  ctaDesc,
  ctaClassName,
  ctaDescClassName,
  titleClassName,
  descClassName,
  listClassName,
  rowReverse,
  contentClassName,
  pill
}: ContentSectionProps) => {
  const renderPill = ({ className, textClassName, text }: Pill) => (
    <div
      className={cn(
        'mb-2 inline-block justify-center rounded-full bg-pressedPrimary px-6 py-1',
        className
      )}
    >
      <p className={textClassName}>
        <TextFormatter text={text} />
      </p>
    </div>
  );

  const renderTitle = () => (
    <h3
      className={cn(
        'mt-8 text-[32px] font-bold leading-none md:mt-0 md:text-[72px]',
        titleClassName
      )}
    >
      {title}
    </h3>
  );

  const renderDescription = () => (
    <p
      className={cn(
        'mt-6 max-w-prose text-2xl font-medium md:mt-10',
        descClassName
      )}
    >
      <TextFormatter text={desc!} />
    </p>
  );

  const renderList = () => (
    <ul className={cn('list-disc p-4 text-xl md:text-2xl', listClassName)}>
      {list!.map((item) => (
        <ListItem key={item} item={item} />
      ))}
    </ul>
  );

  const renderCTA = () => (
    <Button
      onClick={cta}
      className={cn(
        'mt-6 h-[72px] w-full bg-black tracking-wide text-white md:-ml-4 md:mt-10 md:max-w-[426px]',
        ctaClassName
      )}
      variant="primary"
    >
      {ctaTitle}
    </Button>
  );

  return (
    <div
      className={cn(
        'flex flex-col items-center justify-center text-black md:items-start',
        {
          'items-end': rowReverse
        }
      )}
    >
      <div className={cn('max-w-[592px] pb-6 md:pb-0', contentClassName)}>
        {!!pill && renderPill(pill)}
        {renderTitle()}
        {!!desc && renderDescription()}
        {!!list && renderList()}
        {!!ctaDesc && (
          <p
            className={cn(
              'mt-4 whitespace-pre-line text-2xl font-semibold md:-ml-4',
              ctaDescClassName
            )}
          >
            {ctaDesc}
          </p>
        )}
        {cta && ctaTitle && renderCTA()}
      </div>
    </div>
  );
};

export default ContentSection;
