import React from 'react';

import { Footer } from '@features/landing-page';

const CampaignPageFooter = () => {
  return (
    <div className="flex justify-center px-4 pb-12 md:py-20">
      <div className="w-full max-w-[1216px]">
        <Footer />
      </div>
    </div>
  );
};

export default CampaignPageFooter;
